import React from "react";
import Page from "./../components/Page";
import SEO from "./../components/SEO";
import WithSideNav from "../components/WithSideNav";
import Section from "../components/Section";

function TeamPage() {
  const sideNavProps = [
    {
      title: "About Us",
      link: "/about"
    },
    {
      title: "Team & Collaborators",
      link: "/team"
    },
    {
      title: "Get Support",
      link: "/get-support"
    },
    {
      title: "Funding History",
      link: "/history"
    }
  ];

  return (
    <Page>
      <SEO title="About | OHIF" />
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="About">
          <div className="text-white">
            The Open Health Imaging Foundation (OHIF) was launched in 2015
            through a development partnership between Massachusetts General
            Hospital (MGH) Department of Radiology and Radical Imaging to create
            an open source web-based medical imaging platform for the global
            community. The partnership combines the project management, user
            experience design, and product testing experience of the MGH group
            with the software development and system design expertise of Radical
            Imaging.
          </div>
          <div className="text-white">
            Funding for OHIF has been primarily through research grants to MGH,
            direct funding from MGH Department of Radiology and its Precision
            Imaging Metrics clinical trials informatics project, Radical Imaging
            through in-kind support, and academic-industry technical
            collaborations among MGH, Radical, and companies using the OHIF
            Framework.
          </div>
          <div className="text-white">
            Learn more about OHIF's commercially-permissive MIT open source
            software license{" "}
            <span>
              <a
                href="https://github.com/OHIF/Viewers/blob/master/LICENSE"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-100 hover:opacity-75"
              >
                here.
              </a>
            </span>
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default TeamPage;
